<template>
  <div>
    <HomePageTop/>
    <div class="paymentSuccessPage">
      <div class="EmployeesAccountTitle flexAndCenter">
        <div class="fontSize20 color333 fontWeightBold">{{ $fanyi("支付完成") }}</div>
      </div>
      <div class="logo">
        <img alt="" src="../../assets/1688/commoditySearchPageLogo.png">
      </div>
      <div class="paymentSuccessExplainContainer flexAndCenterAndCenter">
        <img
            :src="$store.state.payDetails.type !== 'bank'?require('../../assets/icon-1.png'):require('../../assets/bankPaymentWarningIcon.svg')"
            class="successImg"/>
        <div v-if="$store.state.payDetails.type !== 'bank'" class="paymentSuccessText">{{ $fanyi("操作成功") }}</div>
        <div v-else class="bankPaymentText">
          入金操作は、まだ完了しておりません。<br>
          先程、入金報告して頂いた銀行、日時、金額、入金名義でお振込みをお願いします。
        </div>
        <div v-if="$store.state.payDetails.type === 'bank'" class="flexAndCenter bankListContainer">
          <div class="bankContainer">
            <img src="../../assets/bank1.png">
            <div style="margin-left:2px">
              <div class="flexAndCenter">
                <div class="labelName">銀行名：</div>
                <div>楽天銀行</div>
              </div>
              <div class="flexAndCenter">
                <div class="labelName">支店番号：</div>
                <div>252</div>
              </div>
              <div class="flexAndCenter">
                <div class="labelName">支店名：</div>
                <div>第二営業支店</div>
              </div>
              <div class="flexAndCenter">
                <div class="labelName">預金種目：</div>
                <div>普通預金</div>
              </div>
              <div class="flexAndCenter">
                <div class="labelName">口座番号：</div>
                <div>7977668</div>
              </div>
              <div class="flexAndCenter">
                <div class="labelName">口座名義：</div>
                <div>ラクマート（カ）</div>
              </div>
            </div>
          </div>
          <div class="bankContainer">
            <img src="../../assets/bank2.png">
            <div style="margin-left:5px">
              <div class="flexAndCenter">
                <div class="labelName">銀行名：</div>
                <div>ゆうちょ銀行</div>
              </div>
              <div class="flexAndCenter">
                <div class="labelName">支店番号：</div>
                <div>四〇八支店</div>
              </div>
              <div class="flexAndCenter">
                <div class="labelName">口座番号：</div>
                <div>5631876</div>
              </div>
              <div class="flexAndCenter">
                <div class="labelName">口座名義：</div>
                <div>ラクマート（カ</div>
              </div>
              <div style="margin-top: 30px">
                <div>ゆうちょ銀行からのお振込</div>
                <div>14040-56318761</div>
              </div>
            </div>
          </div>
          <div class="bankContainer" style="padding:32px 0 0 25px">
            <img src="../../assets/bank3.png" style="padding-left:35px">
            <div class="flex">
              <div class="labelName">銀行名：</div>
              <div>
                <div>paypay銀行</div>
                <div>（旧）ジャパンネット銀行</div>
              </div>
            </div>
            <div class="flexAndCenter">
              <div class="labelName">支店番号：</div>
              <div>005</div>
            </div>
            <div class="flexAndCenter">
              <div class="labelName">支店名：</div>
              <div>ビジネス営業部</div>
            </div>
            <div class="flexAndCenter">
              <div class="labelName">預金種目：</div>
              <div>普通預金</div>
            </div>
            <div class="flexAndCenter">
              <div class="labelName">口座番号：</div>
              <div>1928861</div>
            </div>
            <div class="flexAndCenter">
              <div class="labelName">口座名義：</div>
              <div>ラクマート（カ）ジャパン</div>
            </div>
          </div>
        </div>
        <div>
          <div v-if="$store.state.payDetails.type !== 'bank'" class="paypalPaymentSuccessExplainText">
            {{ `＊ ${$fanyi("已从您的余额扣除待支付的金额。")}` }}
          </div>
          <div class="flexAndCenter paypalPaymentSuccessExplainText" v-if="$store.state.payDetails.type !== 'bank'">
            <!--            具体金额变更明细可在-->
            詳細について
            <div class="colorB4272B">{{ $fanyi("账单") }}</div>
            <!--            里查看。-->
            より確認可能です。
          </div>
          <div v-if="$store.state.payDetails.type !== 'bank'" class="footerText">{{ $fanyi("请多关照") }}。</div>
        </div>
        <div class="paymentSuccessMessageContainer">
          <div style="margin-bottom: 16px">{{ $fanyi("出入金编号") }}：{{ $store.state.payDetails.serial_number }}</div>
          <div class="flexAndCenter" style="margin-left: 0">
            {{ $store.state.payDetails.priceType === 'go' ? '入金予定額' : '入金額' }}：
            <div>{{ Number($store.state.payDetails.pay_amount_jpy) }}</div>
            円
          </div>
        </div>
        <div v-if="$store.state.payDetails.type === 'bank'"
             style="margin-top: 18px;text-align: center;line-height: 25px">
          <div class="flexAndCenter"><!--            具体金额变更明细可在-->詳細について
            <div class="colorB4272B">{{ $fanyi("账单") }}</div>
            より確認可能です。<!--            里查看。-->
          </div>
          <div>よろしくお願いします</div>
        </div>
        <div v-if="$store.state.payDetails.priceType === 'go'" class="messageContainer" style="margin-bottom: -40px">
          ※入金報告とお振込みは同じタイミング（連続）で行って頂くようにお願いいたします。
          時間が経過しますと、為替変動による残高不足で注文が実行出来ません。<br>
          <p style="margin-bottom: 10px"></p>
          ※上記対策として、若干多めの入金をお勧めさせて頂きます。
          ※入金報告額とお振込み額は必ず一致させるようにお願いします。納期恐れの一因になってしまいます。
        </div>
        <div class="btnListContainer flexAndCenter">
          <div @click="$fun.routerToPage('/')">{{ $fanyi("返回首页") }}</div>
          <div @click="$fun.routerToPage('/user/index')">{{ $fanyi("个人中心") }}</div>
          <div @click="$fun.routerToPage('/user/churujinList')">{{ $fanyi("账单") }}</div>
        </div>
      </div>
    </div>
    <Foot/>
  </div>
</template>
<script>
import HomePageTop from "../../components/head/HomePageTop.vue";
import Foot from "@/components/foot/Foot.vue";

export default {
  components: {
    Foot,
    HomePageTop
  }
}
</script>

<style lang="scss" scoped>
.paymentSuccessPage {
  width: 1400px;
  margin: 20px auto 0;
  background: #fff;

  .EmployeesAccountTitle {
    background: #FFFFFF;
    height: 73px;
    justify-content: space-between;
    padding: 0 26px;
    border-radius: 10px 10px 0 0;
    border-bottom: 1px solid #DFDFDF;
  }

  .bankListContainer {
    margin-bottom: 60px;
    width: 1080px;
    justify-content: space-between;

    .bankContainer {
      width: 320px;
      height: 300px;
      background: #FFFFFF;
      border: 1px solid #DFDFDF;
      border-radius: 10px;
      padding: 34px 0 0 50px;

      img {
        width: 200px;
        height: 34px;
        margin-bottom: 35px;
      }

      .labelName {
        text-align: right;
        min-width: 90px;
      }

      .selectBankIcon {
        width: 30px;
        height: 30px;
        background: #B4272B;
        border-radius: 10px 0 10px 0;
        justify-content: center;
        position: absolute;
        bottom: 0;
        right: -2px;
        color: #fff;

        img {
          width: 15px !important;
          height: 19px !important;
          margin-bottom: 0;
        }
      }
    }
  }

  .logo {
    display: flex;
    justify-content: center;
    margin-top: 36px;
  }

  .paymentSuccessExplainContainer {
    margin: 36px 0 30px;
    flex-direction: column;
    padding-bottom: 156px;

    img {
      width: 80px;
      height: 80px;
      margin-bottom: 26px;
    }

    .paymentSuccessText {
      color: #333333;
      font-size: 18px;
      margin-bottom: 51px;
      text-align: center;
    }

    .bankPaymentText {
      color: #B4272B;
      font-size: 20px;
      text-align: center;
      line-height: 30px;
      margin-bottom: 60px;
    }

    .paypalPaymentSuccessExplainText {
      font-size: 18px;
      color: #333333;
      text-align: center;
    }

    .footerText {
      color: #333333;
      font-size: 18px;
      margin: 30px 0;
      text-align: center;
    }

    .paymentSuccessMessageContainer {
      width: 550px;
      height: 120px;
      background: #F9F9F9;
      border-radius: 10px;
      padding: 35px 0 0 60px;

      div {
        color: #333333;
        font-size: 18px;
      }

      .flexAndCenter {
        margin-left: 54px;

        div {
          color: #B4272B;
          font-size: 18px;
          font-weight: bold;
        }
      }
    }

    .messageContainer {
      padding: 20px;
      width: 550px;
      background: #F9F9F9;
      margin-top: 20px;
      line-height: 30px;
    }

    .btnListContainer {
      margin-top: 60px;
      width: 550px;
      justify-content: space-between;

      div {
        width: 150px;
        height: 38px;
        background: #FFFFFF;
        border: 1px solid #B4272B;
        border-radius: 3px;
        line-height: 38px;
        text-align: center;
        cursor: pointer;
        color: #B4272B;
        font-size: 14px;
      }

      div:last-child {
        color: #FEFEFE;
        background: #B4272B;
      }
    }
  }
}
</style>
